body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}

.border-thick {
  border-width: 3px !important;
}

.td-label {
  display: none;
}

a {
  color: white !important;
  text-decoration: none;
}

.langOption:hover {
  background: #000080;
  color: white;
  border: #000080 solid 1px;
}

.arrow-box {
  position: relative;
  background: #ffffff;
}
.arrow-box:after,
.arrow-box:before {
  bottom: 100%;
  left: 85%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-box:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 8px;
  margin-left: -8px;
}

.arrow-box:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 8px;
  margin-left: -8px;
}

a:hover {
  text-decoration: none !important;
}

.text-traxisblue {
  color: #000080 !important;
}

.border-traxisblue {
  border-color: #000080 !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #000080 white;
}

*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #f8f9fa !important;
}
*::-webkit-scrollbar-thumb {
  background-color: #000080;
  border-radius: 10px;
  border: 2px solid white;
}

.btn {
  border: 0px !important;
}

.btn:disabled {
  background-color: #6c757d !important;
}

.bg-traxisblue {
  background-color: #000080 !important;
}

.ease-transition {
  transition: ease 0.3s;
}

.ease-transition-fast {
  transition: ease 0.15s;
}

.collapse-arrow-collapsed {
  transform: rotateX(180deg);
}

.payment-method-choice {
  color: #000080 !important;
  fill: #dddddd;
}

.payment-method-choice > div > svg {
  width: 8em;
  height: 8em;
}

.payment-method-choice:hover {
  color: white !important;
  background-color: #000080 !important;
  fill: white;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .payment-method-choice > div > svg {
    width: 100% !important;
    height: 100% !important;
  }

  .payment-method-choice > h3 {
    font-size: 0.75em;
  }

  .payment-method-choice {
    height: 75%;
  }
}

/*
@media only screen and (max-width: 540px) {
  tr:nth-of-type(odd) {
    background-color: #f8f9fa !important;
  }
  tr:nth-of-type(even) {
    background-color: #ffffff !important;
  }

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  table {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  /* Hide table headers (but not display: none;, for accessibility)
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  td:nth-of-type(1) {
    color: #000080 !important;
    font-weight: bold;
  }

  /*
	td { 
		border: none!important;
		border-bottom: 1px solid #eee; 
		position: relative;
    overflow-x: visible;
    color: #6c757d!important;
    left: 50%;
	}
  

  td {
    color: #6c757d !important;
    display: flex;
    position: relative;
    border: none !important;
    border-bottom: 1px solid #eee;
    justify-content: space-between !important;
  }

  .td-label {
    display: inline-block;
    font-weight: bold;
    color: #000080 !important;
  }

  td:before {
    position: absolute;
    font-weight: bold;
    color: #6c757d !important;
  }
}
*/
